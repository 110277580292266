<template>
  <div>
    <div class="headInfoFemale">
      <div class="info">
        <div class="img">
          <img v-if="userInfo.gender == 0" src="../../common/img/girl.png"/>
          <img v-if="userInfo.gender == 1" src="../../common/img/boy.png"/>
        </div>
        <div class="name">{{ obj.stuName }}</div>
        <!--        <div class="class">{{ obj.className }}</div>-->
      </div>
      <div style="text-align:left;margin-top: 30px" @click="showPicker=true">{{ this.currentPlan.name }}▼</div>
    </div>
    <div class="score">
      <div
          :class="
          level <= 2
            ? 'circle level1'
            : level == 3
            ? 'circle level2'
            : level == 4
            ? 'circle level3'
            : level == 5
            ? 'circle level4'
            : 'circle'
        "
      ></div>
      <div
          :class="
          level <= 2
            ? 'number number1'
            : level == 3
            ? 'number number2'
            : level == 4
            ? 'number number3'
            : level == 5
            ? 'number number4'
            : 'number'
        "
      >
        {{ obj.totalScore }}
        <span>分</span>
      </div>
      <div class="title">综合分数</div>
    </div>
    <level-bar
        :standard="standard"
        :level="level"
        :title="title"
        :line="line"
    ></level-bar>
    <div class="headText">
      <div class="headTextContent">
        <div>
          {{ obj.comment }}
        </div>
      </div>
    </div>

    <div class="content">
      <van-collapse
          style="margin-bottom: 30px"
          v-model="activeNames"
          v-for="(item, index) in project"
          :key="index"
      >
        <van-collapse-item :name="index">
          <template #title>
            <img :src="item.logoUrl"/>
            <label>{{ item.fitt }}</label>
          </template>
          <template #value>
            <div class="value">{{ item.result }}</div>
            <div v-if="item.fitt == '体重'">
              <div style="color:#E03F3F" v-if="item.level == 2">肥胖</div>
              <div style="color:#EFAD4F" v-else-if="item.level == 3">偏瘦</div>
              <div style="color:#C1CF27" v-else-if="item.level == 4">偏胖</div>
              <div style="color:#38C75B" v-else-if="item.level == 5">正常</div>
            </div>
            <div v-else>
              <div class="state2" v-if="item.level == 2">需努力</div>
              <div class="state3" v-else-if="item.level == 3">合格</div>
              <div class="state4" v-else-if="item.level == 4">良好</div>
              <div class="state5" v-else-if="item.level == 5">优秀</div>
            </div>
          </template>
          <template #default>
            <div class="contentText">
              <div class="top">
                全班排名&nbsp;第<label style="color: red">{{
                  item.classSort
                }}</label
              >名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;超过同年龄段<label
                  style="color: red"
              >{{ item.ageSort }}%</label
              >的孩子
              </div>
              <div class="middle">
                <weight-level-bar
                    v-if="item.fitt == '体重'"
                    :standard="item.standard"
                    :level="item.level"
                    :title="weightTitle"
                    :line="weightLine"
                ></weight-level-bar>
                <level-bar
                    v-else
                    :standard="item.standard"
                    :level="item.level"
                    :title="title"
                    :line="line"
                ></level-bar>
              </div>
              <div class="bottom">
                {{ item.describe }}
              </div>
            </div>
          </template>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div style="height: 20px"></div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          show-toolbar
          title="标题"
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>

import api from '../../common/js/api';
import levelBar from '../../components/levelBar.vue';
import weightLevelBar from "../../components/weightlevelBar";
import {saveToken2} from "../../common/js/cache";

export default {
  data() {
    return {
      showPicker: false,
      columns: [],
      activeNames: [],
      obj: {},
      project: [],
      standard: ['60', '75', '83'],
      title: ['需努力', '合格', '良好', '优秀'],
      line: [
        'border: 1px solid #E03F3F',
        'border: 1px solid #EFAD4F',
        'border: 1px solid #C1CF27',
        'border: 1px solid #38C75B',
      ],
      weightTitle: ['偏轻', '正常', '偏胖', '肥胖'],
      weightLine: [
        'border: 1px solid #EFAD4F',
        'border: 1px solid #38C75B',
        'border: 1px solid #EFAD4F',
        'border: 1px solid #E03F3F',
      ],
      level: 5,
      stuId: -200,
      userInfo: {},
      planList: [],
      currentPlan: {},
    };
  },

  components: {levelBar, weightLevelBar},

  computed: {},

  created() {
    let token = this.$route.query.token;
    saveToken2(token)
    this.stuId = this.$route.query.stuId;
    this.getPlanList();
  },

  mounted() {
  },

  methods: {
    onConfirm(value, index) {
      this.currentPlan = this.planList[index]
      this.showPicker = false
      this.getReport()
    },
    onCancel() {
      this.showPicker = false
    },
    getPlanList() {
      this.$postSan(api.planList, {
        schoolId: this.$route.query.schoolId,
      }).then((res) => {
        this.planList = res.data.list
        this.currentPlan = this.planList[0]
        this.columns = this.planList.map(plan => plan.name)
        this.getReport();
      });
    },
    getReport() {
      this.$postSan(this.currentPlan.testVersion === "1" ? api.getReport : api.getReport23, {
        schoolId: this.$route.query.schoolId,
        stuId: this.stuId,
        planId: this.currentPlan.id
      }).then((res) => {
        if (res.code === 0) {
          this.obj = res.data;
          this.project = res.data.data;
          this.userInfo.gender = res.data.gender
          let score = res.data.totalScore;
          if (score < 60) {
            this.level = 2;
          } else if (score >= 60 && score < 75) {
            this.level = 3;
          } else if (score >= 75 && score < 83) {
            this.level = 4;
          } else {
            this.level = 5;
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.headInfoFemale {
  clear: both;
  width: 130px;
  height: 64px;
  // background: url("http://ra.wechat.daishuquan.com/static/images/result-child-female.png")
  //   no-repeat 50%;
  background: url('../../common/img/imgBorder.png') no-repeat 50%;
  background-size: contain;
  text-align: right;
  float: left;
  margin-top: 35px;

  .info {
    margin-right: 60px;

    .img {
      width: 58px;
      height: 58px;
      margin-left: 70px;
      margin-top: 3px;

      img {
        width: 58px;
        height: 58px;
      }
    }

    .name {
      font-weight: bold;
      font-size: 15px;
      margin-top: -40px;
      margin-right: 10px;
      color: #2668aa;
    }

    .class {
      font-size: 11px;
      margin-right: 10px;
      color: #5991c8;
    }
  }
}

.score {
  width: 100%;
  height: 100px;
  clear: both;
  // background: url("http://ra.wechat.daishuquan.com/static/images/phy_result_title.png")
  //   no-repeat 50%;
  background-size: contain;
  text-align: center;
  margin-top: 20px;

  .circle {
    width: 190px;
    height: 98px;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .level1 {
    border: 5px solid #e03f3f;
    border-bottom: none;
  }

  .level2 {
    border: 5px solid #efad4f;
    border-bottom: none;
  }

  .level3 {
    border: 5px solid #c1cf27;
    border-bottom: none;
  }

  .level4 {
    border: 5px solid #38c75b;
    border-bottom: none;
  }

  .number {
    color: #c42032;
    font-size: 30px;
    padding-top: 30px;
    font-weight: 500;

    span {
      font-size: 10px;
    }
  }

  .number1 {
    color: #e03f3f;
  }

  .number2 {
    color: #efad4f;
  }

  .number3 {
    color: #c1cf27;
  }

  .number4 {
    color: #38c75b;
  }

  .title {
    font-size: 15px;
    padding-top: 10px;
  }
}

.headText {
  margin-top: 20px;

  .headTextTitle {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid #c42032;
    height: 28px;
    width: 36px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
  }

  .headTextContent {
    border: 1px solid #58a8db;
    border-radius: 6px;
    background-color: white;
    margin: 0 20px 0 20px;

    div {
      padding: 15px 15px 32px 15px;
      color: #444;
      font-size: 14px;
      text-align: justify;
    }
  }
}

.content {
  padding: 0 25px 0 25px;
  margin-top: 20px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  label {
    font-weight: 500;
    font-size: 15px;
  }

  /deep/ .van-cell {
    align-items: center;
    border-radius: 6px 6px 0 0;
    border: 1px solid #dcdcdc;

    .van-cell__title {
      display: flex;
      align-items: center;
    }

    .van-cell__value {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .value {
        color: black;
        font-size: 12px;
        margin-right: 20px;
      }

      .state2 {
        color: #e03f3f;
        font-weight: 500;
        font-size: 15px;
        margin-right: 5px;
      }

      .state3 {
        color: #efad4f;
        font-weight: 500;
        font-size: 15px;
        margin-right: 5px;
      }

      .state4 {
        color: #c1cf27;
        font-weight: 500;
        font-size: 15px;
        margin-right: 5px;
      }

      .state5 {
        color: #38c75b;
        font-weight: 500;
        font-size: 15px;
        margin-right: 5px;
      }
    }
  }

  /deep/ .van-collapse-item__wrapper {
    border-radius: 0 0 6px 6px;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;

    .contentText {
      font-size: 14px;
      color: black;

      .top {
        text-align: center;
      }

      .middle {
        .headbar {
          width: 100%;
        }
      }

      .bottom {
        margin-top: 20px;
      }
    }
  }
}
</style>
