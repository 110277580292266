<template>
  <div class="headbar">
    <ul class="navbar">
      <li>
        <div class="line first" :style="line[0]"></div>
        <div class="icon" v-if="level == 3">
          <img src="../common/img/level.png" />
        </div>
        <div class="lineSocre">{{ standard[0] }}</div>
        <div class="rankText">{{title[0]}}</div>
      </li>
      <li>
        <div class="line second" :style="line[1]"></div>
        <div class="icon" v-if="level == 5">
          <img src="../common/img/level.png" />
        </div>
        <div class="lineSocre">{{ standard[1] }}</div>
        <div class="divide"></div>
        <div class="rankText">{{title[1]}}</div>
      </li>
      <li>
        <div class="line third" :style="line[2]"></div>
        <div class="icon" v-if="level == 4">
          <img src="../common/img/level.png" />
        </div>
        <div class="lineSocre">{{ standard[2] }}</div>
        <div class="divide"></div>
        <div class="rankText">{{title[2]}}</div>
      </li>
      <li>
        <div class="line fourth" :style="line[3]"></div>
        <div class="icon" v-if="level == 2">
          <img src="../common/img/level.png" />
        </div>
        <div class="divide"></div>
        <div class="rankText">{{title[3]}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["standard", "level","title","line"],

  components: {},

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang='scss' scoped>
.headbar {
  width: 80%;
  height: 55px;
  margin-top: 20px;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
  .navbar {
    overflow: hidden;
    height: 100%;
    padding-left: 0;
    margin-bottom: 20px;
    li {
      list-style: none;
      font-size: 12px;
      float: left;
      width: 25%;
      margin-top: 30px;
      position: relative;
      .icon {
        width: 21px;
        height: 21px;
        overflow: hidden;
        position: absolute;
        top: -38%;
        left: 36%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .line {
        width: 86%;
        position: relative;
        transform: translateX(-50%);
        left: 50%;
      }
      .first {
        border: 1px solid #E03F3F;
      }
      .second {
        border: 1px solid #EFAD4F;
      }
      .third {
        border: 1px solid #C1CF27;
      }
      .fourth {
        border: 1px solid #38C75B;
      }
      .lineSocre {
        width: 30px;
        position: absolute;
        right: -16px;
        top: -25px;
        text-align: center;
      }
      .divide {
        height: 15px;
        border-right: 1px dashed;
        position: absolute;
        top: -7px;
      }
      .rankText {
        padding-top: 8px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>